@import "@/scss/_variables.scss";
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import '_variables.scss';

.chapter-image {
      display: none !important;
    }

    #chapterscontainer li.active-chapter, #chapterscontainer-mobile li.active-chapter {
      color: white;
      background-color: #D54C01FF;
    }

    @media (hover: hover) {
      #chapterscontainer li:hover {
        color: white;
        background-color: #D54C01FF;
      }
    }

* {
  box-sizing: border-box;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  width: 200% !important;
  max-height: 40em !important;
}

.vjs-menu li {
  text-align: left !important;
  padding-left: 10px !important;
}

#chapterscontainer, #chapterscontainer-mobile {
  background-color: #f1f1f1;
  overflow-y: auto;
  padding: 0;
}

#chapterscontainer ul, #chapterscontainer-mobile ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#chapterscontainer li, #chapterscontainer-mobile li {
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  background-color: white;
  margin: 2px 3px;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 8px;
}

#chapterscontainer-mobile {
  display: none;
}


#chapterscontainer li.active-chapter, #chapterscontainer-mobile li.active-chapter {
  color: white;
  background-color: #0139AB;
}

.chapter-image {
  width: 100px;
  margin-right: 8px;
}

.list-content {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  #chapterscontainer {
    display: none;
  }
  #chapterscontainer-mobile {
    display: block;
  }
  #chapterscontainer-mobile li {
    margin: 2px 3px;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 14px;
  }

  .icon-list li {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 1439px) {
  .chapter-image {
    display: none;
  }

  h1 {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 1919px) {
  .chapter-image {
    width: 75px;
  }
}

@media (hover: hover) {
  #chapterscontainer li:hover {
    color: white;
    background-color: #0139AB;
  }
}
